<template>
  <div class="content">
    <div class="box box1">
      <StoryTitle>{{ baseInfo.storyIntroEnglish }}</StoryTitle>
      <div class="intro">
        <PlaceholderImage
          :width="1435"
          :height="500"
          :src="baseInfo.storyIntroImage"
        />
        <MaskBox>
          <div
            class="text"
            v-html="baseInfo.storyIntroContent"
          ></div>
        </MaskBox>
      </div>
    </div>
    <div class="box2">
      <div class="box">
        <StoryTitle>{{ baseInfo.storyPotEnglish }}</StoryTitle>
      </div>
      <FixBg :src="require('@/assets/ShuJiuXiang/story-bg.jpg')">
        <div class="box">
          <VerticalTitle
            class="verticalTitle"
            :title="baseInfo.storyPotChinese"
          />
        </div>
      </FixBg>
    </div>
    <div class="box box3">
      <div class="videoBox"><video
          :src="baseInfo.storyPotVideo"
          :poster="baseInfo.storyPotImage"
          preload="auto"
          ref="video"
          controls="controls"
          class="video"
          id="video"
        >您的浏览器不支持 video 标签。</video>
        <div
          class="desc"
          v-html="baseInfo.storyPotContent"
        ></div>
      </div>
    </div>
    <div class="box box4">
      <div
        class="cont"
        v-loading="loading"
      >
        <StoryTitle class="storyTitle">{{ baseInfo.storyProductEnglish }}</StoryTitle>
        <StoryCarousel
          :data="characteristicData"
          class="storyCarousel"
          v-if="characteristicData.length"
        />
        <el-empty v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { StoryTitle } from '../components/Title';
import { Placeholder } from '@/components/Image';
import MaskBox from '@/components/Mask';
import { FixBg } from '@/components/Image';
import { VerticalTitle } from '@/components/Title';
import { StoryCarousel } from '../components/Carousel';
import { debounce } from 'lodash';
import { getScrollTop } from '@/utils/common';
import { mapState } from 'vuex';
import { getAdvantage } from '@/api/ShuJiuXiang/brand';

export default {
  components: {
    StoryTitle,
    PlaceholderImage: Placeholder,
    MaskBox,
    FixBg,
    VerticalTitle,
    StoryCarousel,
  },
  data() {
    return {
      characteristicData: [],
      isPlay: false,
      loading: false,
    };
  },
  mounted() {
    this.getData();
    this.onScroll();
    document.addEventListener('scroll', debounce(this.onScroll, 50));
  },
  destroyed() {
    document.removeEventListener('scroll', this.onScroll);
  },
  computed: {
    ...mapState({
      baseInfo: (state) => state.sjx.brand.baseInfo,
    }),
  },
  methods: {
    onScroll() {
      const st = getScrollTop();
      const { y = 0 } = this.$refs.video?.getBoundingClientRect() || {};
      if (st > y) {
        this.isPlay = true;
      }
    },
    async getData() {
      this.loading = true;
      try {
        const res = await getAdvantage();
        this.loading = false;
        this.characteristicData = res.map((d) => ({
          id: d.advantageId,
          title: d.advantageTitle,
          desc: d.advantageContent,
          imgUrl: d.advantageImage,
          icon: d.advantageIco,
        }));
      } catch (err) {
        this.loading = false;
      }
    },
  },
  watch: {
    isPlay() {
      document.getElementById('video')?.play();
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
.content {
  overflow: hidden;
}
.box {
  padding: 0 math.percentage(math.div(190, 1920)) 0 math.percentage(math.div(235, 1920));
}
.box1 {
  padding-top: math.percentage(math.div(146, 1920));
  .intro {
    margin: 40px 0 0 60px;
  }
}
.intro {
  position: relative;
  color: #fff;
  .text {
    width: 800px;
    max-width: 90%;
    margin: 0 auto;
    text-align: center;
    font-size: 18px;
    line-height: math.div(28, 18);
  }
}
.box2 {
  padding-top: 58px;
  .verticalTitle {
    margin-left: 60px;
  }
  ::v-deep {
    .fixbg {
      margin-top: 36px;
      padding: math.percentage(math.div(103, 1920)) 0 math.percentage(math.div(146, 1920));
    }
  }
}
.box3 {
  display: flex;
  justify-content: flex-end;
}
.videoBox {
  margin-top: math.percentage(math.div(-569, 1920 - 190 - 235));
  width: math.percentage(math.div(1227, 1920 - 190 - 235));
  .video {
    width: 100%;
    background: #fff;
    vertical-align: bottom;
  }
  .desc {
    margin-top: 42px;
  }
}
.box4 {
  padding-top: math.percentage(math.div(148, 1920));
  padding-bottom: math.percentage(math.div(267 - 35 - 80, 1920));
  .cont {
    position: relative;
  }
  .storyCarousel {
    margin-left: 60px;
  }
  .storyTitle {
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 19;
    width: 590px;
    max-width: 100%;
  }
}
@media screen and (max-width: $res-point-1) {
  .box {
    padding-left: math.percentage(math.div(190, 1920));
  }
}
@media screen and (max-width: $res-point-2) {
  .box {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media screen and (max-width: $res-point-3) {
  .intro {
    .text {
      font-size: 16px;
    }
  }
  .box4 {
    .storyTitle {
      width: 490px;
    }
  }
}
@media screen and (max-width: $res-point-5) {
  .box4 {
    .storyTitle {
      position: static;
      margin-bottom: 36px;
      width: 100%;
    }
  }
}
@media screen and (max-width: $res-point-6) {
  .box1 {
    .intro {
      margin-left: 0;
      margin-top: 36px;
    }
  }
  .box2 {
    .verticalTitle {
      margin-left: 0;
    }
  }
  .videoBox {
    width: 100%;
    padding-left: 100px;
  }
  .box4 {
    .storyCarousel {
      margin-left: 0;
    }
    padding-bottom: math.percentage(math.div(267, 1920));
  }
}
@media screen and (max-width: $res-point-8) {
  .box1 {
    .intro {
      margin-top: 36px * 0.8 * 0.8;
    }
  }
  .intro {
    .text {
      font-size: 14px;
      text-align: left;
    }
    ::v-deep {
      .maskLayer {
        position: relative;
        z-index: 2;
        padding: 10% 0;
      }
      .el-image {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        padding-bottom: 0 !important;
        .el-image__inner {
          object-fit: cover;
        }
      }
    }
  }
  .box2 {
    padding-top: 58px * 0.8;
    ::v-deep {
      .fixbg {
        margin-top: 36px * 0.8 * 0.8;
      }
    }
  }
  .videoBox {
    padding-left: 100px * 0.8;
    .desc {
      margin-top: 42px * 0.8;
    }
  }
  .box4 {
    .storyTitle {
      margin-bottom: 36px * 0.8 * 0.8;
    }
  }
}
@media screen and (max-width: $res-point-9) {
  .box1 {
    .intro {
      margin-top: 15px;
    }
  }
  .box2 {
    padding-top: 30px;
    ::v-deep {
      .fixbg {
        margin-top: 15px;
      }
    }
  }
  .videoBox {
    padding-left: 35px;
    .desc {
      margin-top: 15px;
      font-size: 12px;
    }
  }
  .box4 {
    .storyTitle {
      margin-bottom: 15px;
    }
  }
}
</style>